import React from "react"
import { Link, graphql } from "gatsby"
import moment from 'moment'

import Layout from "../../components/layout"
import Content, { HTMLContent } from '../../components/content-template'
import './blog-template.scss'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  pageContext,
  description,
  tags,
  title,
  date
}) => {
  const PostContent = contentComponent || Content
  return (
    <section className="section">
      <div className="container content">
        <div className="columns">
          <div className="column">
            <div className="title-row">
              <h1 className="title">
                {title}
              </h1>
              <small>{moment(date).format('DD MMM YYYY')}</small>
            </div>
            <PostContent content={content} />
          </div>
          <nav>
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <h3>
                {pageContext && pageContext.previous !== null && (
                  <Link to={pageContext.previous.fields.slug} rel="prev">
                    ← {pageContext.previous.frontmatter.title}
                  </Link>
                )}
              </h3>
              <h3>
                {pageContext && pageContext.next !== null && (
                  <Link to={pageContext.next.fields.slug} rel="next">
                    {pageContext.next.frontmatter.title} →
                  </Link>
                )}
              </h3>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  )
}

const BlogPost = ({ data, pageContext }) => {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        pageContext={pageContext ? pageContext : null}
        description={post.frontmatter.description}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
      />
    </Layout>
  )
}

export default BlogPost

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        title
        description
        date
      }
    }
  }
`